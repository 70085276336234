import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ onLinkClick }) => {
  const { t } = useTranslation();
  return (
    <footer className="foot text-white pt-5 pb-4">
      <div className="container text-center text-md-left">
        <div className="row text-center text-md-left">
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h5 className="text-uppercase mb-4 font-weight-bold title">
              Powered by{" "}
              <span>
                <img
                  src="/../../React.png" // Yerel dosya yolu
                  alt="React JS"
                  style={{ width: "20px", height: "20px", margin: "0 5px" }}
                />
                REACT JS
                <img
                  src="/../../Express.png"// Yerel dosya yolu
                  alt=""
                  style={{ width: "25px", height: "25px", margin: "0 15px" }}
                />
                EXPRESS JS
                <img
                  src="/../../MongoDB.png" // Yerel dosya yolu
                  alt="Mongo DB"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "2px", // Yazıya daha yakın hale getirildi
                    marginLeft: "5px",
                  }}
                />
                MONGO DB
              </span>
            </h5>
            <p className="sub-title">{t("fo1.12")}</p>
          </div>
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h5 className="text-uppercase mb-4 font-weight-bold title">
              {t("fo2.13")}
            </h5>
            <p className="link-hover">
              <a href="https://www.deepseek.com/">Deepseek</a>
            </p>
            <p className="link-hover">
              <a href="https://midjourney.com">Midjourney</a>
            </p>
            <p className="link-hover">
              <a href="https://www.aerialsphere.com">Aerialsphere</a>
            </p>
            <p className="link-hover">
              <a href="https://www.arstechnica.com">Arstechnica</a>
            </p>
          </div>
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3 sub-title">
            <h5 className="text-uppercase mb-4 font-weight-bold title">
              {t("fo7.18")}
            </h5>
            <p>İstanbul/Türkiye</p>
            <p>erdem_celik@outlook.com</p>
            <p>+90 0506 316 25 80</p>
          </div>
        </div>
        <hr className="mb-4" />
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-8">
            <p>
              {" "}
              {t("fo13.25")}
              <span style={{ textDecoration: "none" }}>
                <strong className="title"> erdemcelik.com</strong>
              </span>
            </p>
          </div>
          <div className="col-md-5 col-lg-4">
            <div className="text-center text-md-right">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    href="https://x.com/erdemcelx"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-floating btn-sm text-white"
                    style={{ fontSize: "23px" }}
                  >
                    <i className="fab fa-twitter icons-color"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;